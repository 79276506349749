"use strict";
import Dropdown from "./libs/dropdown";
import lazyLoad from "./libs/lazyload";
import hideLabels from "./libs/hideLabels";
import scrollAnimate from "./libs/scrollAnimate";
import videoCarousel from "./libs/videoCarousel";
import carouselOverlay from "./libs/carouselOverlay";

document.addEventListener("DOMContentLoaded", function() {
  Dropdown(".js-nav-trigger", ".js-nav-items");
  lazyLoad();
  hideLabels();
  scrollAnimate();
});

window.onload = function() {
  // Load the video carousel once videos are in DOM
  videoCarousel();
  carouselOverlay();

  var forLastExec,
    delay = 100, // delay between calls
    throttled = false; // are we currently throttled?

  // window.resize event listener
  window.addEventListener("resize", function() {
    // only run if we're not throttled
    if (!throttled) {
      // actual callback action
      carouselOverlay();
      // we're throttled!
      throttled = true;
      // set a timeout to un-throttle
      setTimeout(function() {
        throttled = false;
      }, delay);
    }
    clearTimeout(forLastExec);
    forLastExec = setTimeout(carouselOverlay, delay);
  });
};
