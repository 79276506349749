export default function videoCarousel() {
    var mySwiper = new Swiper('.js-video-carousel', {
        loop: false,
        centeredSlides: true,
        slidesPerView: 'auto',
        keyboard: true,
        navigation: {
          nextEl: '.js-carousel-next',
          prevEl: '.js-carousel-prev',
        },
        slidesPerView: 'auto',
        spaceBetween: 80,      
        breakpoints: {
            400: {
                spaceBetween: 10
            },
            760: {
                spaceBetween: 20
            },
            1140: {
                spaceBetween: 30
            },
            1300: {
                spaceBetween: 80
            }                   
        }, 
        on: {
            init: function () {
                const revealEl = document.querySelector('.hide-el');
                revealEl.classList.add('reveal-el');
            },
            slideChange: stopVideos
        }
    }); 

    // Stop all the videos
    function stopVideos() {
        let youtubeVids = document.querySelectorAll(".js-youtube iframe");
        let vimeoVids = document.querySelectorAll(".js-vimeo iframe");
        let videosOverlay = document.querySelectorAll(".video-overlay");

            // Loop youtube vids to stop all 
        for(let i=0; i < youtubeVids.length; i++) {
            youtubeVids[i].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' +   '","args":""}', '*');
        }

        // Loop vimeo vids to stop all 
        for(let i=0; i < vimeoVids.length; i++) {
            let iframe = vimeoVids[i];
            let player = new Vimeo.Player(iframe); // Pass the element to the Vimeo.Player constructor to control existing video

            player.unload().then(function() {
                // Vimeo video unloaded;
            }).catch(function(error) {
                console.log('There was an error in unloading the Vimeo videos. Check the stopVideos() function.')
            });        
        }

        // Reveal all the video overlays again
        for(let i=0; i < videosOverlay.length; i++) {
            videosOverlay[i].style.display = "block";
        }
    }
  }
  