export default function carouselOverlay() {
    const overlays = document.getElementsByClassName("js-carousel-overlay");
    const container = document.querySelector('.js-carousel-container');
    let carouselW = container.offsetWidth;
    let overlayW = 0;
  
    // take width of container
    // take width of video and the gutter bewteen videos
    // overlay width = container - (video + gutters) / 2
  
    if(carouselW > 1300) {
      overlayW = (carouselW - 1095) / 2;
    } else if(carouselW > 1140) {
      overlayW = (carouselW - 1000) / 2;
    } else {
      overlayW = (carouselW - 760) / 2;
    }
  
    for(let i=0; i < overlays.length; i++) {
      overlays[i].style.width = overlayW + 'px';
    }      
  }