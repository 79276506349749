"use strict";

import {hasClass, addClass, removeClass} from './classNameUtils';

// Primary Navigation function
export default function Dropdown(trigger, content) {
  const dropdownTrigger = document.querySelector(trigger);
  const dropdownItems = document.querySelector(content);
  const navItems = document.querySelectorAll('.nav-primary li a');

  if (window.innerWidth < 900) {
    addClass(dropdownItems,'closed');
  }

  // Slide Nav up and Down with CSS class & calculated height
  dropdownTrigger.addEventListener("click", () => {
    if(hasClass(dropdownItems, 'closed') == true){
      dropdownItems.style.height = dropdownItems.scrollHeight + 'px';
      removeClass(dropdownItems, 'closed');
      addClass(dropdownTrigger,'active');
    }
    else {
      addClass(dropdownItems, 'closed');
      removeClass(dropdownTrigger,'active');
    }
  }, true);


  // Close nav on item click
  navItems.forEach(item => {
      item.addEventListener("click", (event) => {
          addClass(dropdownItems, 'closed');
          removeClass(dropdownTrigger,'active');      
      }, true);
  });


  window.addEventListener('resize', () => {
    removeClass(dropdownTrigger,'active');
    dropdownItems.style.height = 'auto';

    if (window.innerWidth < 900) {
      addClass(dropdownItems,'closed');
    }
    else {
      removeClass(dropdownItems, 'closed');
      dropdownItems.style.height = dropdownItems.scrollHeight + 'px';
    }
  }, true);

};